import CheckIcon from "@components/svg/checkIcon";
import { ReactElement } from "react";
import style from "./style.module.scss";
import colors from "@styles/variables/bestearpluglawyersVariables.module.scss";

export default function Lawsuit(): ReactElement {
    const standards = [
        "Used 3M dual-ended Combat Arms Earplugs",
        "Served in the military between 2003 and 2015",
        "Have been diagnosed with tinnitus or hearing loss due to military service",
    ];
    return (
        <div className={style.wrapper}>
            <div>
                <h2>
                    Could You Qualify for a Lawsuit Related to 3M Dual-Ended
                    Combat Arms Earplugs?
                </h2>
                <p>
                    If you suspect you may be eligible for a lawsuit concerning
                    3M earplugs, we recommend reaching out to our experts for a
                    complimentary case evaluation. It costs you nothing, and
                    there may be time constraints to consider. If you meet the
                    criteria, we'll file your defective earplugs claim within
                    the applicable timeframe and gather all necessary evidence
                    to pursue the compensation you deserve. To be eligible for a
                    3M earplug lawsuit, you must meet the following three
                    criteria:
                </p>
                <ul className="my-4">
                    {standards.map((standard, index) => (
                        <>
                            <li key={index} className="flex items-baseline">
                                <CheckIcon
                                    className="w-3 h-3 mr-1"
                                    fill={colors.green}
                                />
                                <span className="w-11/12">{standard}</span>
                            </li>
                        </>
                    ))}
                </ul>
                <p>
                    If you are still an active-duty member of the military, you
                    might not currently qualify for a lawsuit. However, ensure
                    that your hearing loss has been adequately documented in
                    your medical records.
                </p>
            </div>
        </div>
    );
}
